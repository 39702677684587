export default {
  'css': `my-frame .frame,[is="my-frame"] .frame{ position: relative; } my-frame .thumb,[is="my-frame"] .thumb{ display: block; width: 100%; } my-frame .underlay,[is="my-frame"] .underlay{ position: absolute; display: flex; top: 0; right: 0; left: 0; bottom: 0; background-color:rgba(255, 255, 255, 0.7); } my-frame .title,[is="my-frame"] .title{ margin: auto; font-size: 1.5em; font-weight: lighter; padding: 0.5em 0 0.5em 0; } my-frame .descr,[is="my-frame"] .descr{ margin: auto; font-size: 1.25em; font-weight: lighter; padding: 1em; } my-frame .capture,[is="my-frame"] .capture{ margin: auto; font-size: 1em; font-weight: lighter; padding-top: 0.2em 0 0.2em 0; } my-frame .underlay,[is="my-frame"] .underlay{ display: none; } my-frame .frame:hover > .underlay,[is="my-frame"] .frame:hover > .underlay{ display: flex; }`,
  'exports': null,

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="frame"><div class="title">Community Building for Germany\'s Largest Startup Co-Working Brand</div><div class="thumb noselect"><img expr10="expr10" width="100%"/></div><div class="underlay"><p class="descr"><a expr11="expr11" href="#gallery/"> </a></p></div><div class="capture">Client: Factory Berlin</div></div>',
      [{
        'redundantAttribute': 'expr10',
        'selector': '[expr10]',

        'expressions': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'src',

          'evaluate': function(scope) {
            return scope.props.image;
          }
        }]
      }, {
        'redundantAttribute': 'expr11',
        'selector': '[expr11]',

        'expressions': [{
          'type': expressionTypes.TEXT,
          'childNodeIndex': 0,

          'evaluate': function(scope) {
            return scope.props.abstract;
          }
        }]
      }]
    );
  },

  'name': 'my-frame'
};