export default {
  'css': null,

  'exports': {
    setInnerHTML(content) {
      this.root.innerHTML = content
    },

    onMounted(props) {
      this.setInnerHTML(props.html)
    },

    onUpdated(props) {
      this.setInnerHTML(props.html)
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template('<span></span>', []);
  },

  'name': 'raw'
};