export default {
  'css': `me .me,[is="me"] .me{ margin: auto; margin-top: 1em; margin-bottom: 1em; font-family: 'Raleway', sans serif; font-size: 2.0em; font-weight: 900; text-align: center; text-transform: uppercase; border: 2px solid black; padding: 0.8em; } me .frame,[is="me"] .frame{ position: relative; padding: 2em; }`,
  'exports': null,

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="frame"><div class="me"><span><a href="#about">Anna Sundukova</a></span></div></div>',
      []
    );
  },

  'name': 'me'
};