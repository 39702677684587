import Gallery from './gallery.riot'
import Social from './social.riot'
import Welcome from './welcome.riot'
import { Router, Route } from '@riotjs/route'

export default {
  'css': null,

  'exports': {
    components: {
      Gallery, Social, Welcome, Router, Route
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template('<router expr3="expr3"></router>', [{
      'type': bindingTypes.TAG,
      'getComponent': getComponent,

      'evaluate': function(scope) {
        return 'router';
      },

      'slots': [{
        'id': 'default',
        'html': '<div class="pure-g noselect"><div class="pure-u-1"><social expr4="expr4"></social></div><div class="pure-u-1"><route expr5="expr5" path="/"></route><route expr7="expr7" path="/#gallery"></route></div></div>',

        'bindings': [{
          'type': bindingTypes.TAG,
          'getComponent': getComponent,

          'evaluate': function(scope) {
            return 'social';
          },

          'slots': [],

          'attributes': [{
            'type': expressionTypes.ATTRIBUTE,
            'name': 'pinterest',

            'evaluate': function(scope) {
              return scope.props.me.pinterest;
            }
          }, {
            'type': expressionTypes.ATTRIBUTE,
            'name': 'linkedin',

            'evaluate': function(scope) {
              return scope.props.me.linkedin;
            }
          }, {
            'type': expressionTypes.ATTRIBUTE,
            'name': 'instagram',

            'evaluate': function(scope) {
              return scope.props.me.instagram;
            }
          }],

          'redundantAttribute': 'expr4',
          'selector': '[expr4]'
        }, {
          'type': bindingTypes.TAG,
          'getComponent': getComponent,

          'evaluate': function(scope) {
            return 'route';
          },

          'slots': [{
            'id': 'default',
            'html': '<welcome expr6="expr6"></welcome>',

            'bindings': [{
              'type': bindingTypes.TAG,
              'getComponent': getComponent,

              'evaluate': function(scope) {
                return 'welcome';
              },

              'slots': [],
              'attributes': [],
              'redundantAttribute': 'expr6',
              'selector': '[expr6]'
            }]
          }],

          'attributes': [{
            'type': expressionTypes.ATTRIBUTE,
            'name': 'path',

            'evaluate': function() {
              return '/';
            }
          }],

          'redundantAttribute': 'expr5',
          'selector': '[expr5]'
        }, {
          'type': bindingTypes.TAG,
          'getComponent': getComponent,

          'evaluate': function(scope) {
            return 'route';
          },

          'slots': [{
            'id': 'default',
            'html': '<gallery expr8="expr8"></gallery>',

            'bindings': [{
              'type': bindingTypes.TAG,
              'getComponent': getComponent,

              'evaluate': function(scope) {
                return 'gallery';
              },

              'slots': [],
              'attributes': [],
              'redundantAttribute': 'expr8',
              'selector': '[expr8]'
            }]
          }],

          'attributes': [{
            'type': expressionTypes.ATTRIBUTE,
            'name': 'path',

            'evaluate': function() {
              return '/#gallery';
            }
          }],

          'redundantAttribute': 'expr7',
          'selector': '[expr7]'
        }]
      }],

      'attributes': [],
      'redundantAttribute': 'expr3',
      'selector': '[expr3]'
    }]);
  },

  'name': 'app'
};