export default {
  'css': `you .you,[is="you"] .you{ position: relative; font-weight: lighter; text-align: center; text-transform: lowercase; } you .you a:hover,[is="you"] .you a:hover{ border-bottom: 3px solid black; }`,
  'exports': null,

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="you"><span><a href="https://github.com/rdanitz">&copy; rdanitz</a></span></div>',
      []
    );
  },

  'name': 'you'
};