import me from './me.riot'
import myFrame from './my-frame.riot'
import you from './you.riot'
import Image from './images/*.png'

export default {
  'css': null,

  'exports': {
    components: { me, myFrame, you, Image }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="pure-u-1-3"><me expr6="expr6"></me></div><div class="pure-u-1-3"><my-frame expr7="expr7" abstract="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et"></my-frame></div><div class="pure-u-1-3"><my-frame expr8="expr8" abstract="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et"></my-frame></div><div class="pure-u-1-3"><my-frame expr9="expr9" abstract="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et"></my-frame></div>',
      [{
        'type': bindingTypes.TAG,
        'getComponent': getComponent,

        'evaluate': function(scope) {
          return 'me';
        },

        'slots': [],
        'attributes': [],
        'redundantAttribute': 'expr6',
        'selector': '[expr6]'
      }, {
        'type': bindingTypes.TAG,
        'getComponent': getComponent,

        'evaluate': function(scope) {
          return 'my-frame';
        },

        'slots': [],

        'attributes': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'image',

          'evaluate': function(scope) {
            return Image.img1;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'abstract',

          'evaluate': function() {
            return 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et';
          }
        }],

        'redundantAttribute': 'expr7',
        'selector': '[expr7]'
      }, {
        'type': bindingTypes.TAG,
        'getComponent': getComponent,

        'evaluate': function(scope) {
          return 'my-frame';
        },

        'slots': [],

        'attributes': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'image',

          'evaluate': function(scope) {
            return Image.img2;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'abstract',

          'evaluate': function() {
            return 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et';
          }
        }],

        'redundantAttribute': 'expr8',
        'selector': '[expr8]'
      }, {
        'type': bindingTypes.TAG,
        'getComponent': getComponent,

        'evaluate': function(scope) {
          return 'my-frame';
        },

        'slots': [],

        'attributes': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'image',

          'evaluate': function(scope) {
            return Image.img3;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'abstract',

          'evaluate': function() {
            return 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et';
          }
        }],

        'redundantAttribute': 'expr9',
        'selector': '[expr9]'
      }]
    );
  },

  'name': 'gallery'
};